import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';

import { scrollToTop } from '../store/actions';
import { getKey } from '../helpers';
import BlogList from '../components/compound/BlogList';

/**
 * Page Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Page = props => {
  const dispatch = useDispatch();
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const posts = getKey(props, 'data.allHubspotPost.edges');
  const callToAction = getKey(props, 'data.callToAction.edges[0].node.callToAction');
  const numberOfBlogPages = getKey(props, 'pageContext.numberOfBlogPages');
  const currentBlogPage = getKey(props, 'pageContext.currentBlogPage');

  dispatch(scrollToTop());

  return (
    <>
      {metaTitle && (
        <Helmet
          title="Alestra - Blog"
          meta={[
            { content: metaTitle, name: 'description' },
            { content: `${metaTitle}, alestra`, name: 'keywords' },
          ]}
        >
          <html lang="es" />
        </Helmet>
      )}
      <BlogList
        key={`blogPage${currentBlogPage}`}
        posts={posts}
        numberOfBlogPages={numberOfBlogPages}
        currentBlogPage={currentBlogPage}
        callToAction={callToAction}
      />
    </>
  );
};

export default Page;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allHubspotPost: allHubspotPost(
      sort: { fields: published, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        topics: {
          elemMatch: {
            id: {
              nin: [24358099125, 51758783852, 51766335248]
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          slug
          title
          summary
          published_format(formatString: "D/M/YYYY")
          feature_image {
            alt_text
            url
          }
        }
      }
    }
    callToAction: allStrapiPages(
      filter: {
        slug: {
          eq: "/blog"
        }
      }
    ) {
      edges {
        node {
          callToAction {
            url
            image {
              url
            }
          }
        }
      }
    }
  }
`;
